import Pusher from 'pusher-js';
const { 
  VUE_APP_PUSHER_APP_KEY, 
  VUE_APP_PUSHER_APP_CLUSTER
} = process.env;
let pusher = undefined;

function createSocketInstance() {
    if(!VUE_APP_PUSHER_APP_KEY || !VUE_APP_PUSHER_APP_CLUSTER){
      return false;
    }
    Pusher.Runtime.createXHR = function () {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      return xhr;
    };

  pusher = new Pusher(VUE_APP_PUSHER_APP_KEY, {
    cluster: VUE_APP_PUSHER_APP_CLUSTER,
    forceTLS: true,
    authEndpoint: `${window.location.origin}/broadcasting/auth`
  });
  return pusher;
}

const createListener = (channelName, eventName, eventListener) => {

  if(!pusher) {
    pusher = createSocketInstance();
  }
  if(pusher){
    const channel = pusher.subscribe(`${channelName}`);
  
    channel.bind('pusher:subscription_error', function(data) {
      console.error(data);
    });
    channel.bind(`${eventName}`, data => {
      if(eventListener) {
        return eventListener(data); 
      }
    });
  } 
};
const removeListener = eventName => {
  if(channel) {
    channel.unbind(eventName);
  }
};
const disconnectSocket = () => {
  if(pusher) {
    pusher.disconnect();
    pusher = undefined;
  }
};
 
export default {
  createListener,
  disconnectSocket,
  removeListener,
  createSocketInstance
};
