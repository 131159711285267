<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <b-col
      class="content-header-left mb-2 mt-1"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2
            class="content-header-title float-left pr-1 mb-0"
            v-b-tooltip.hover.top="pageTooltip"
          >
            {{ pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item :to="loggedUsersHomeRoute">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="{ name: item.name }"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive';

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  computed: {
    ...mapGetters("app", ["customPageTitle"]),

    pageTitle() {
      return this.customPageTitle || this.$route.meta.pageTitle
    },
    loggedUsersHomeRoute () {
      if(this.isAdminPortal) {
        return {name: 'admin-home'};
      } 
      if(this.isChampionPortal) {
        return {name: 'champion-home'};
      } 
      if(this.isParticipantPortal) {
        return {name: 'participant-home'};
      }
      if (this.isParticipantPortal) {
        return { name: "participant-home" };
      }
      return { name: "admin-home" };
    },
    isAdminPortal() {
      return this.$store.getters["app/isAdminPortal"];
    },
    isChampionPortal() {
      return this.$store.getters["app/isChampionPortal"];
    },
    isParticipantPortal() {
      return this.$store.getters["app/isParticipantPortal"];
    },
    pageTooltip() {
      return this.$route.meta?.pageTooltip || ""
    }
  }
};
</script>
