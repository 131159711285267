<template>
  <layout-vertical ref="layoutVertical">
    <router-view />
    <div
      slot="navbar"
      class="w-100"
    >
      <navbarslot @toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </div>
    <div slot="footer">
      <ul class="main-menu-footer">
        <li>
          <router-link
            :to="footerTermsRoute"
          >
            Terms of Acceptable Use
          </router-link>
        </li>
        <li class="copyright">
          © {{ new Date().getFullYear() }} Art of Mentoring
        </li>
        <li>
          <router-link
            :to="footerPrivacyRoute"
          >
            Privacy Policy
          </router-link>
        </li>
      </ul>
    </div>
  </layout-vertical>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutVertical from '../layout-vertical/LayoutVertical.vue'; /// intentar cambiar esta linea
import { $themeConfig } from '@themeConfig';
import Navbarslot from '../slots/Navbarslot.vue';

export default {
  components: {
    LayoutVertical,
    Navbarslot
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
  computed: {
    ...mapGetters("app", ["isParticipantPortal"]),

    footerTermsRoute() {
      if (this.isParticipantPortal) {
        return { name: 'participant-terms' };
      }
      return { name: 'app-terms' };
    },
    footerPrivacyRoute() {
      if (this.isParticipantPortal) {
        return { name: 'participant-privacy' };
      }
      return { name: 'app-privacy' };
    }
  },
  methods: {
    toggleVerticalMenuActive() {
      this.$refs.layoutVertical.toggleVerticalMenuActive();
    }
  }
};
</script>

<style lang="scss" scoped>
.main-menu-footer {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  li {
    a {
      color: #625f6e;
    }

    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 16px;
    }

    &.copyright {
      @media (max-width: 767px) {
        order: 3;
      }
    }
  }
}
</style>
