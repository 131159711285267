<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
        <!-- Email compose modal -->
        <div class="email-application">
          <email-compose
            v-model="shallShowEmailComposeModal"
            :is-program-communications="true"
            :is-public="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import EmailCompose from "@/participants/communications/EmailCompose.vue";
import { ref } from '@vue/composition-api';

export default {
  components: {
    AppBreadcrumb,
    EmailCompose
  },
  watch: {
    '$route.query': {
      handler: function(query) {
        if (query.isContactProgramManager) {
          this.shallShowEmailComposeModal = true;
          return;
        }
        this.shallShowEmailComposeModal = false;
      },
      deep: true,
      immediate: true
    },
  },
  setup() {
    // Compose
    const shallShowEmailComposeModal = ref(false);
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition, contentWidth, shallShowEmailComposeModal
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
</style>
