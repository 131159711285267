<template>
  <ul>
    <li
      v-if="showBackToChampion"
      class="nav-item back-to-menu-item"
    >
      <a
        href="javascript:void(0)"
        class="d-flex align-items-center"
        @click="handleSwitchToChampion"
      >
        <feather-icon icon="ChevronsLeftIcon" />
        <span class="menu-title text-truncate">Back to Champion</span>
      </a>
    </li>
    <li
      v-if="showBackToAdmin"
      class="nav-item back-to-menu-item"
    >
      <a
        href="javascript:void(0)"
        class="d-flex align-items-center"
        @click="handleSwitchToAdmin"
      >
        <feather-icon icon="ChevronsLeftIcon" />
        <span class="menu-title text-truncate">Back to Admin</span>
      </a>
    </li>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="(item, index) in items"
      :key="`${item.route}-${index}`"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@/layouts/utils';
import { provide, ref } from '@vue/composition-api';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';
import { userRoles as modelUserRoles, userStatus } from '@/models';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import { mapGetters } from 'vuex';

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
    ...mapGetters('programs',['defaultProgramId']),
    currentUserRoles () {
      return this.$store.getters['profile/profile'].roles;
    },
    isAdminRoleActive () {
      return this.$store.getters['profile/profile'].user_roles.filter(r => r.role_id === modelUserRoles.ADMIN && r.status_id === userStatus.ACTIVE).length > 0;
    },
    showBackToAdmin () {
      return this.isChampionPortal && this.currentUserRoles.includes(modelUserRoles.ADMIN) && this.isAdminRoleActive;
    },
    showBackToChampion () {
      return this.isAdminPortal && this.currentUserRoles.includes(modelUserRoles.CHAMPION);
    }
  },
  watch: {
    '$route.query': {
      handler: function() {
        setTimeout(() => document.getElementById('contactProgramManager')?.classList.remove("active"), 10);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleSwitchToChampion () {
      // Change current user role to champion
      const role = modelUserRoles.CHAMPION;
      this.$store.commit('profile/SET_USER_ROLE', role);
      const to = getHomeRouteForLoggedInUser(role);
      to.params = { id: this.defaultProgramId };
      this.$router.push(to);
    },

    handleSwitchToAdmin () {
      // Change current user role to admin
      const role = modelUserRoles.ADMIN;
      this.$store.commit('profile/SET_USER_ROLE', role);
      const to = getHomeRouteForLoggedInUser(role);
      this.$router.push(to);
    },
  },
  setup() {
    provide('openGroups', ref([]));

    return {
      resolveNavItemComponent,
    };
  },
};
</script>
<style lang="scss" scoped>
.back-to-menu-item {
  margin-bottom: 20px;
}
</style>