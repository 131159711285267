export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'champion-home',
  },
  {
    title: 'Program',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Settings',
        route: { name: 'champion-program-general'},
      },
      {
        title: 'Key dates',
        route: { name: 'champion-program-key-dates-list' },
      },
      {
        title: 'Landing Page',
        route: { name: 'champion-program-landing-page' },
      },
      {
        title: 'Applications',
        route: { name: 'champion-program-application' },
      },
      {
        title: 'Surveys',
        route: { name: 'champion-program-surveys-list' },
      },
      {
        title: 'Communications',
        route: { name: 'champion-program-communications' },
      },
      {
        title: 'Resources',
        route: { name: 'champion-program-resources' },
      },
      {
        title: 'Training',
        route: { name: 'champion-program-training-list' },
      },
    ],
  },
  {
    title: 'Participants',
    icon: 'UserIcon',
    route: 'champion-participants'
  },
  {
    title: 'Groups',
    icon: 'UsersIcon',
    route: 'champion-participants-groups'
  },
  {
    title: 'Match participants',
    icon: 'UserPlusIcon',
    route: 'champion-matches-matching-pools'
  },
  {
    title: 'Reports',
    route: 'champion-reports',
    icon: 'CheckSquareIcon',
  }
];
