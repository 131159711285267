export default [
  /*======================================================= A D M I N ================================================  */
  {
    title: 'Dashboard',
    route: 'admin-home',
    icon: 'HomeIcon',
  },
  {
    title: 'Organisations',
    route: 'admin-clients',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Programs',
    route: 'admin-programs',
    icon: 'ListIcon',
  },
  {
    title: 'Templates',
    route: 'admin-templates',
    icon: 'ListIcon',
  },
  {
    title: 'Repository',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Questions',
        route: 'admin-application-questions',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'Application Lists',
        route: 'admin-application-list',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'Survey Lists',
        route: 'admin-survey-list',
        icon: 'CheckSquareIcon',
      },
      {
        title: 'Resources',
        route: 'admin-resources-list',
      },
      {
        title: 'Training',
        route: 'admin-training-list',
      },
      {
        title: 'Communications',
        route: 'admin-communications-list',
      }
    ],
  },
  {
    title: 'Administrators',
    route: 'admin-users',
    icon: 'UsersIcon',
  },
  {
    title: 'Languages',
    icon: 'FileTextIcon',
    route: 'admin-languages-list'
  },
  {
    title: 'Platform Metrics',
    icon: 'FileTextIcon',
    route: 'platform-metrics'
  }
];
