<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
    :class="{'dropdown-user--participant': isParticipantPortal}"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name mb-0">
          {{ profile.full_name || profile.username }}
        </p>
        <span class="user-status">{{ displayUserRoles }}</span>
      </div>
      <b-avatar
        size="40"
        :src="profileImage"
        variant="light-success"
      >
        <feather-icon
          v-if="!profile.full_name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <!-- Dropdown for participants -->
    <template v-if="isParticipantPortal">
      <b-dropdown-item
        :to="{ name: 'participant-profile' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>My Profile</span>
      </b-dropdown-item>

      <!-- <b-dropdown-item
        :to="{ name: 'participant-my-programs' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CircleIcon"
          class="mr-50"
        />
        <span>My Programs</span>
      </b-dropdown-item> -->

      <b-dropdown-divider />

      <b-dropdown-item
        :to="{ name: 'participant-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Settings</span>
      </b-dropdown-item>

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </template>

    <!-- Dropdown for admin and champion -->
    <template v-else>
      <b-dropdown-item
        v-if="userIsChampion"
        :to="championEditProfile"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Profile</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="changePasswordRoute"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="LockIcon"
          class="mr-50"
        />
        <span>Change Password</span>
      </b-dropdown-item>

      <b-dropdown-divider />

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import { avatarText } from "@core/utils/filter";
import authService from "@/services/authService";
import { makeErrorToast } from "@/libs/utils";
import {
  getAfterLogoutRoute,
  clearLocalStorage
} from "@/auth/utils";
import { userRoles, userRolesDisplay } from "@models/userRoles";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    };
  },
  computed: {
    ...mapGetters("app", [
      "isAdminPortal",
      "isParticipantPortal"
    ]),

    currentProgram() {
      return this.$store.getters["app/currentProgram"];
    },
    profile() {
      return this.$store.getters["profile/profile"];
    },
    currentUserRole() {
      return userRolesDisplay[this.profile.role];
    },
    mentorDisplay() {
      return this.$store.getters["participants/mentorDisplay"];
    },
    menteeDisplay() {
      return this.$store.getters["participants/menteeDisplay"];
    },
    profileImage () {
      return this.profile.profile_image?.url;
    },
    displayUserRoles() {
      const displayUserRoles = [];
      if (this.isParticipantPortal) {
        if (this.profile.roles.includes(userRoles.MENTOR)) {
          displayUserRoles.push(this.mentorDisplay);
        }
        if (this.profile.roles.includes(userRoles.MENTEE)) {
          displayUserRoles.push(this.menteeDisplay);
        }
        return displayUserRoles.join(", ");
      }
      return this.currentUserRole;
    },
    changePasswordRoute() {
      return this.isAdminPortal
        ? { name: 'admin-change-password' }
        : { name: 'champion-change-password' };
    },
    userIsChampion () {
      return this.profile.role === userRoles.CHAMPION || this.profile.role === userRoles.PROGRAM_ADMIN;
    },
    championEditProfile() {
      return {name: 'champion-profile-edit', params: {userId: this.profile.id}};
    }
  },
  methods: {
    ...mapMutations("profile", {
      RESET_PROFILE_STATE: "RESET_STATE",
    }),
    ...mapMutations("participants", {
      RESET_PARTICIPANT_STATE: "RESET_STATE",
    }),
    ...mapMutations("programs", {
      RESET_PROGRAM_STATE: "RESET_PROGRAM_STATE",
    }),

    async logout() {
      try {
        await authService.logout();
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast());
        return;
      }

      const redirectRoute = getAfterLogoutRoute(this.profile.role);
      if (this.isParticipantPortal && this.currentProgram) {
        redirectRoute.params = this.currentProgram.path;
      }

      // Remove userData from localStorage
      clearLocalStorage();
      this.RESET_PROFILE_STATE();
      this.RESET_PARTICIPANT_STATE();
      this.RESET_PROGRAM_STATE();
      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push(redirectRoute);
    },
  },
};
</script>
