<template>
  <b-nav-item-dropdown
    v-if="!isAdminPortal"
    ref="notificationDropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="messagesCountUnread"
        badge-classes="bg-danger"
        class="text-primary"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ messagesCountUnread }} New
        </b-badge>
      </div>
    </li>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <aom-skeleton-loader v-if="isLoading" />
      <b-link
        v-for="(notification, index) in notifications"
        :key="index"
        @click="routeToEvent(notification)"
      >
        <b-media>
          <b-avatar
            size="md"
            class="white float-left mr-1"
          >
            <feather-icon
              v-if="notification[0].notifiable_type === notificationType.NEW_REGO"
              icon="UsersIcon"
              size="16"
              stroke-width="1"
            />
            <feather-icon
              v-if="notification[0].notifiable_type === notificationType.APP_COMPLETE || notification[0].type_id === notificationType.SURVEY_COMPLETE"
              icon="FileIcon"
              size="16"
              stroke-width="1"
            />
            <feather-icon
              v-if="notification[0].notifiable_type === notificationType.PROGRAM_MATCH"
              icon="UsersIcon"
              size="16"
              stroke-width="1"
            />
          </b-avatar>
          <p
            class="media-heading font-weight-bolder"
          >
            <span v-if="notification[0].notifiable_type === notificationType.NEW_REGO">
              <span v-if="notification.length === 1">{{ notification.length }} New Applicant</span>
              <span v-if="notification.length > 1">{{ notification.length }} New Applicants</span>
            </span>
            <span v-if="notification[0].notifiable_type === notificationType.APP_COMPLETE && notification[0].notifiable.type_id === formTypes.APPLICATION">
              <span v-if="notification.length === 1">{{ notification.length }} New application submitted</span>
              <span v-if="notification.length > 1">{{ notification.length }} New application submissions</span>
            </span>
            <span v-if="notification[0].notifiable_type === notificationType.SURVEY_COMPLETE && notification[0].notifiable.type_id === formTypes.SURVEY">
              <span v-if="notification.length === 1">{{ getSurveyNotificationLabel(notification) }}</span>
              <span v-if="notification.length > 1">{{ getSurveyNotificationLabel(notification) }}</span>
            </span>
            <span v-if="notification[0].notifiable_type === notificationType.PROGRAM_MATCH">
              <span v-if="notification[0].notifiable.status_id === matchStatus.ACTIVE && notification.length === 1">You have a new match</span>
              <span v-if="notification[0].notifiable.status_id === matchStatus.CANCELLED && notification.length === 1"> Your match has been cancelled</span>
              <span v-if="notification[0].notifiable.status_id === matchStatus.COMPLETED && notification.length === 1"> Your match has been completed</span>
              <span v-if="notification.length > 1 ">{{ notification.length }} New match updates</span>
            </span>
          </p>
          <small class="notification-text">{{ localeDateStringFromIsoDateTime(notification[0].created_at) }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-if="isChampionPortal"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="readAllNotifications"
      >
        Read all notifications
      </b-button>
      <b-button
        v-if="isParticipantPortal"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="readAllNotifications"
      >
        Read all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';
import { commsService } from '@services';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { loaderTypes, topicTypes, notificationType, socketEvents, formTypes, matchStatus, userRoles } from '@models';
import { mapGetters } from 'vuex';
import { localeDateStringFromIsoDateTime, formatDateTimeFromIsoDateTime, makeErrorToast } from '@/libs/utils';
import AoMWebSocket from "@aom-core/AomWebsocket.js";
import { getUserData } from '@/auth/utils';

export default {
  name: 'NotificationDropdown',
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
    AomSkeletonLoader,
    BAvatar
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      notifications: [],
      unreadNotifications: [],
      messagesCountUnread: 0
    };
  },
  computed: {
    ...mapGetters('app', ['isChampionPortal', 'isAdminPortal', 'isParticipantPortal']),
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram', 'defaultProgramPortal']),
    ...mapGetters('profile',['loggedUserId']),

    currentUserRole() {
      return this.$store.getters['profile/profile'].role;
    }
  },
  watch: {
    defaultProgramId: {
      handler(n) {
        if (n) {
          if (this.isChampionPortal || this.isParticipantPortal) {
            this.createSocketListener();
            this.fetchProgramNotifications();
          }
        }
      },
      immediate: true
    },
    isChampionPortal(n) {
      if(n) {
        this.createSocketListener();
        this.fetchProgramNotifications();
      }
    },
    isParticipantPortal(n) {
      if(n) {
        this.createSocketListener();
        this.fetchProgramNotifications();
      }
    }
  },
  async beforeCreate() {
    const pusher = await AoMWebSocket.createSocketInstance();
    if(pusher){
      this.$root.$on("message-read", () => {
      this.fetchProgramNotifications();
      });
    }   
  },
  mounted() {
     process.env.VUE_APP_PUSHER_APP_KEY && this.createSocketListener();
  },
  unmounted() {
    AoMWebSocket.removeListener(`private-program.${this.defaultProgramId}`);
    AoMWebSocket.removeListener(`private-User.${this.loggedUserId}`);
    AoMWebSocket.disconnectSocket();
  },
  methods: {
    async createSocketListener() {
      if(this.isChampionPortal && this.defaultProgramId) {
        await AoMWebSocket.createListener(`private-program.${this.defaultProgramId}`, socketEvents.NewUserRegistration, this.newSocketEvent);
        await AoMWebSocket.createListener(`private-program.${this.defaultProgramId}`, socketEvents.NewFormSubmission, this.newSocketEvent);
      }
      if(this.isParticipantPortal && this.loggedUserId) {
        await AoMWebSocket.createListener(`private-User.${this.loggedUserId}`, socketEvents.NewMessage, this.newSocketEvent);
        await AoMWebSocket.createListener(`private-User.${this.loggedUserId}`, socketEvents.MatchCancelled, this.newSocketEvent);
        await AoMWebSocket.createListener(`private-User.${this.loggedUserId}`, socketEvents.MatchComplete, this.newSocketEvent);
        await AoMWebSocket.createListener(`private-User.${this.loggedUserId}`, socketEvents.MatchPublished, this.newSocketEvent);
      }
    },
    newSocketEvent(args) {
      if(args) {
        this.fetchProgramNotifications();
      }
    },
    getSurveyNotificationLabel(notification) {
      if (notification[0]?.topic_sent_messages[0]?.subject == 'New Survey Available') {
        return notification.length > 1? `${notification.length} New surveys available`: "New survey available";
      } else {
        return notification.length > 1? `${notification.length} New survey submissions`: "New survey submitted";
      }
    },
    async fetchProgramNotifications() {
      try {
        const user = getUserData();
        this.isLoading = true;
        const programId = this.defaultProgramId;
        if(programId && this.currentUserRole !== userRoles.PARTICIPANT_CANDIDATE) {
          const response = await commsService.getProgramsComms(programId, {
            columnFilters: [
              {field: 'type_id', value: topicTypes.NOTIFICATION},
              {field:"topic_sent_messages.recipient_id", value: user.id},
              {field:"topic_sent_messages.read_at", operator:"null"}
            ],
            sort: [{field: "created_at", type:'desc'}],
          });
          const {data} = response;
          this.unreadNotifications = data.items;
          this.sortAndGroupNotifications(data);
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    sortAndGroupNotifications(data) {
      const formatDateArray = data.items.map(i => ({...i, setDate: formatDateTimeFromIsoDateTime(i.created_at, false, 'Y-M-D')}));
      const filterRemainingArray = formatDateArray.filter(m => m.notifiable_type !== notificationType.NEW_MESSAGE);
      const groupedData = filterRemainingArray.reduce((result, obj) => {
        
        if (obj.notifiable) {
          const key = obj.setDate + '|' + obj.notifiable_type + '|' + obj.notifiable?.type_id;
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(obj);
        }
        return result;
      }, {});
      this.notifications = { ...groupedData };
      this.messagesCountUnread = Number(Object.keys(this.notifications).length);
    },
    routeToEvent(notification) {
      this.closeDropDown();
      if(notification.length === 1 ) {
        if(notification[0]?.notifiable_type === notificationType.APP_COMPLETE || notification[0]?.notifiable_type === notificationType.SURVEY_COMPLETE) {
          if(notification[0]?.notifiable?.type_id === formTypes.APPLICATION) {
            this.routeToUserProfile(notification, '#mentorapp');
          }
          if(notification[0]?.notifiable?.type_id === formTypes.SURVEY) {
            this.routeToUserProfile(notification, '#surveys');
          }
        }
        if(notification[0]?.notifiable_type === notificationType.NEW_REGO) {
          this.routeToUserProfile(notification);
        }
        if(notification[0]?.notifiable_type === notificationType.PROGRAM_MATCH) {
          this.routeToUserMatches(notification);
        }
      } else {
        this.routeToGroupPage(notification);
      }
      this.markMessageAsRead(notification);
      return;
    },
    routeToGroupPage(notification) {
      if(this.isChampionPortal) {
        return  this.$router.push({
          name: 'champion-program-group-notifications', 
          params: { id: this.$route.params.id, notification, groupId: notification[0].id }
        });
      }
      return  this.$router.push({
        name: 'participant-group-notifications', 
        params: { clientSlug: this.$route.params.clientSlug, programPath: this.$route.params.programPath, groupId: notification[0].id, notification  }
      });
    },
    routeToUserProfile(notification, hash) {
      if(hash) {
        return this.$router.push({
          name: 'champion-program-participant',
          params: { id: this.$route.params.id, participantId: notification[0].created_by_id },
          hash
        });
      }
      return this.$router.push({
        name: 'champion-program-participant',
        params: { id: this.$route.params.id, participantId: notification[0].created_by_id }
      });
    },
    routeToUserComms() {
      return this.$router.push({
        name: 'participant-messages',
        params: { clientSlug: this.$route.params.clientSlug, programPath: this.$route.params.programPath }
      });
    },
    routeToUserMatches() {
      return this.$router.push({
        name: 'participant-my-matches',
        params: { clientSlug: this.$route.params.clientSlug, programPath: this.$route.params.programPath }
      });
    },
    readAllNotifications() {
      if (this.isChampionPortal) {
        this.$router.push({
          name: 'champion-program-notifications',
          params: {id: this.defaultProgramId}
        });
      } else if (this.isParticipantPortal) {
        this.$router.push({
          name: 'participant-notifications',
          params: {id: this.defaultProgramId}
        });
      }
      this.closeDropDown();
      if (this.unreadNotifications.length) {
        this.markMessageAsRead(this.unreadNotifications);
      }
    },
    closeDropDown() {
      this.$refs.notificationDropdown.hide();
    },
    async markMessageAsRead(notifications) {
      try {
        const programId = this.$route.params.id || this.defaultProgramId;
        const topics = notifications.map(n => ({id: n.id}));
        await commsService.postTopicMessageMarkRead(programId, {topics: topics});
        this.fetchProgramNotifications();
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      }
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
      loaderTypes,
      localeDateStringFromIsoDateTime,
      notificationType, 
      formTypes, 
      matchStatus
    };
  },
};
</script>

<style lang="scss" scoped>
.b-avatar {
  color: #403f3f;
}
.badge-secondary.white { 
  background: #FFF;
  border: 1px solid #403f3f;
}
</style>
