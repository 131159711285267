<template>
  <b-navbar-nav>
    <b-nav-item-dropdown
      id="program-dropdown"
      variant="link"
      class="program-dropdown programs-list-wrapper"
      left
    >
      <template #button-content>
        <span class="text-body">Programs</span>
        <feather-icon
          icon="ChevronDownIcon"
          size="16"
        />
      </template>
      <b-dropdown-item
        v-for="program in userPrograms"
        :key="program.id"
        class="program-dropdown-wrapper"
        :active="isActiveProgram(program.id)"
        @click="handleChangeProgram(program.id, program)"
      >
        <b-img
          :src="programLogo(program)"
          alt="Fluid image"
          width="20"
        />
        <span class="ml-50">{{ program.client.name }} - {{ program.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg, BNavbarNav } from 'bootstrap-vue';
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { userRoles as modelUserRoles } from '@/models/userRoles';
import { mapGetters } from 'vuex';
import { $themeConfig } from '@themeConfig';
import store from '@/store';
import { setCookie } from '@/libs/utils.js';

const LAST_PROGRAM_ID = 'lastViewProgram';

export default {
  name: 'ProgramDropdown',
  components: {
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BImg
  },
  
  data() {
    return {
      selectedId: undefined
    };
  },
  computed: {
    ...mapGetters('programs', ['programList', 'defaultProgramId']),
    currentUserRole() {
      return this.$store.getters['profile/profile'].role;
    },
    userPrograms() {
      let programListSorted = [...this.programList];
      return programListSorted.sort((a, b) => {
        if (a.client.name.toLowerCase() < b.client.name.toLowerCase()) {
          return -1;
        }
        if (a.client.name.toLowerCase() > b.client.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    handleChangeProgram(programId, program) {
      this.$store.commit('programs/SET_CURRENT_PROGRAM_ID', programId);
      const to = getHomeRouteForLoggedInUser(this.currentUserRole);

      if(this.currentUserRole === modelUserRoles.MENTEE || this.currentUserRole === modelUserRoles.MENTOR){
        to.params = { clientSlug: program.client.path, programPath: program.path};
      }else{
        to.params = { id: programId };
      }
      this.selectedId = programId;
      this.$router.push(to);
      if( program?.primary_program_logo?.url) {
        store.commit('appConfig/UPDATE_LOGO', program.primary_program_logo.url);
      } else {
        store.commit('appConfig/UPDATE_LOGO', require('@/assets/images/logo/AoMLogoWhite250.webp'));
      }
      setCookie(LAST_PROGRAM_ID, this.selectedId, 'Fri, 31 Dec 9999 23:59:59 GMT', null, '/');
    },
    programLogo(program) {
      if(program?.primary_program_logo?.url) {
        return program.primary_program_logo.url;
      } else {
        return $themeConfig.app.appLogoImage;
      }
      
    },
    isActiveProgram(id) {
      return Boolean(Number(id) === Number(this.selectedId) || Number(id) === Number(this.defaultProgramId));
    } 
  }
};
</script>
<style lang="scss" scoped>
.program-dropdown-wrapper {
  min-width: 250px;
  max-height: 80vh;
}
.programs-list-wrapper {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;

}
</style>
<style lang="scss">
#program-dropdown > ul {
    max-height: 80vh;
    overflow-y: scroll;
}
</style>
