<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    :id="item.id"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="comLinkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl';
import { BLink, BBadge } from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';
import { watch, ref, toRef } from '@vue/composition-api';

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const item = toRef(props, 'item');
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(item.value);
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();
    const comLinkProps = ref(linkProps.value);

    watch(
      () => item,
      newItem => {
        if (newItem.value && newItem.value.href) {
          comLinkProps.value = {
            ...comLinkProps.value,
            href: newItem.value.href
          };
        }
      },
      { deep: true }
    );

    return {
      isActive,
      comLinkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },

};
</script>
